import React, { useCallback, useContext, useEffect, useState } from "react";
import "./Footer.scss";

import { MdAttachFile, MdSend } from "react-icons/md";

import { isFirefox, isMobile, isSafari } from "react-device-detect";

import { toast } from "react-toastify";
import { LoadingCircle } from "src/components/Loading/Loading";
import { RequestContext } from "src/contexts/requestContext";
import { useAudioRecorderTools as useAudioTools } from "src/hook/audioRecorder/useAudioRecorderTools";
import useTimer from "src/hook/useTimer";
import getTheme from "src/themes/getTheme";
import { formatTime } from "src/utils/formatTime";
import SendOptMenu from "../SendOptMenu";
import AudioButton from "./AudioButton";

const Footer = ({
  onMessage: handleSendTextMessage,
  onFile,
  suggestions,
  onLocation,
  setShowRecordingAdvice,
  showRecordingAdvice
}) => {
  const currentTheme = getTheme();
  const requestContext = useContext(RequestContext);
  const {
    isRecording,
    startRecording: startRecordingAudioRecorder,
    stopRecording: stopRecordingAudioRecorder,
    cancelRecording: cancelRecordingAudioRecorder,
    isRecordingCompatible
  } = useAudioTools();

  const [message, setMessage] = useState("");
  const [showSendOptMenu, setShowSendOptMenu] = useState(false);
  const { startTimerCount, resetTimerCount, currentTimer } = useTimer(0);

  const submit = useCallback(
    (e, isAudio = false) => {
      if (!isAudio) e.preventDefault();

      const msg = message.trim();
      if (msg !== "") {
        handleSendTextMessage(msg);
        setMessage("");
      }
    },
    [message, handleSendTextMessage]
  );

  const checkBrowser = () => {
    const isFirefox = /^((?!chrome|android).)*Firefox/i.test(
      navigator.userAgent
    );
    if (isFirefox) return "Firefox";

    const isChrome = /^((?!chrome|android).)*Chrome/i.test(navigator.userAgent);
    if (isChrome) return "Chrome";

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) return "Safari";

    return "other";
  };

  async function isMicrophoneAllowed() {
    const currBrowser = checkBrowser();

    try {
      if (currBrowser === "Firefox" && !isMobile) {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        return true;
      }
  
      if (navigator.permissions && navigator.permissions.query) {
        const micStatusQuery = await navigator.permissions.query({ name: "microphone" });
        const micStatus = String(micStatusQuery.state).toLowerCase();

        if (
          micStatus === 'prompt' && 
          isMobile &&
          !isSafari &&
          !isFirefox
        ) {
          await navigator.mediaDevices.getUserMedia({ audio: true });
          window.location.reload();
          return 'prompt'
        }

        return micStatus !== "denied";
      }
  
      await navigator.mediaDevices.getUserMedia({ audio: true });
      return true;
    } catch (err) {
      console.error('Microphone access error:', err);
      return false;
    }
  }

  const startRecording = useCallback(async () => {
    try {
      setShowRecordingAdvice(false);
      const micAllowed = await isMicrophoneAllowed();

      if (micAllowed === 'prompt') return;
      
      if (micAllowed) {
        await startRecordingAudioRecorder();

        if (message.trim() === "" && !isRecording) startTimerCount();
      } else
        toast.warn(
          "Parece que o acesso ao microfone está bloqueado. Verifique suas permissões de áudio.",
          {
            position: "bottom-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
          }
        );
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording, message, startRecordingAudioRecorder, startTimerCount]);

  const cancelRecording = useCallback(async () => {
    try {
      resetTimerCount();
      await cancelRecordingAudioRecorder();
    } catch (error) {
      console.log(error);
    }
  }, [cancelRecordingAudioRecorder, resetTimerCount]);

  const stopRecording = useCallback(async () => {
    if (currentTimer > 0) {
      try {
        resetTimerCount();
        await stopRecordingAudioRecorder();
      } catch (error) {
        console.log(error);
      }
    } else {
      resetTimerCount();
      cancelRecording();
      setShowRecordingAdvice(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimer, resetTimerCount, stopRecordingAudioRecorder]);

  const handleAttachClick = useCallback(() => {
    setShowSendOptMenu(!showSendOptMenu);
  }, [showSendOptMenu]);

  useEffect(() => {
    if (showRecordingAdvice) {
      const timer = setTimeout(() => {
        setShowRecordingAdvice(false);
      }, 3000);
  
      return () => clearTimeout(timer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRecordingAdvice]);

  return (
    <>
      {Boolean(suggestions.length) && (
        <div
          className={`suggestions-row ${
            suggestions.length < 3 ? "suggestions-fix" : ""
          }`}
        >
          {suggestions.map((suggestion, index) => {
            return (
              <button
                key={index}
                className="suggestion"
                onClick={() => handleSendTextMessage(`${suggestion}`)}
              >
                <div>
                  <span>{suggestion}</span>
                </div>
              </button>
            );
          })}
        </div>
      )}

      <div
        className={
          currentTheme?.name === "Namu" ? "footer-bar namu" : "footer-bar"
        }
      >
        {requestContext?.isFetch ? (
          <LoadingCircle />
        ) : (
          <form className="conversation-compose" onSubmit={submit}>
            <div className="conversation-container">
              <input
                className="conversation-container__input-text"
                name="inputs"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={
                  isRecording && (isMobile || window.ReactNativeWebView)
                    ? `${formatTime(currentTimer)}`
                    : "Type a message..."
                }
                autoComplete="off"
                autoFocus
                disabled={isRecording}
              />

              {!isRecording && (
                <div className="conversation-container__input-file">
                  <button type="button" onClick={handleAttachClick}>
                    <MdAttachFile />
                  </button>
                </div>
              )}
            </div>
            {isRecordingCompatible && message.trim() === "" ? (
              <AudioButton
                isRecording={isRecording}
                startRecording={startRecording}
                stopRecording={stopRecording}
                cancelRecording={cancelRecording}
                timer={formatTime(currentTimer)}
                onMessage={handleSendTextMessage}
              />
            ) : (
              <button className="send">
                <div className="circle">
                  <MdSend />
                </div>
              </button>
            )}
            {/* <div className="audio-animation">
              <div className="phrases-container">
                <span className="use-your-span">use sua</span>
                <span className="voice-span">voz</span>
              </div>
            </div> */}
          </form>
        )}
      </div>

      {showSendOptMenu && (
        <SendOptMenu
          onLocation={onLocation}
          sendFile={onFile}
          handleAttachClick={handleAttachClick}
          isRecordingCompatible={isRecordingCompatible}
        />
      )}
    </>
  );
};

export default Footer;
