import React, { useCallback, useRef, useState } from "react";
import { IoIosReverseCamera } from "react-icons/io";
import { MdCameraEnhance, MdKeyboardArrowLeft } from "react-icons/md";
import Webcam from "react-webcam";
import { FILETYPE } from "../../../contexts/store/appReducer";
import "./CameraScreen.scss";

import { v4 as uuidv4 } from "uuid";

export default function CameraScreen(rest) {
  const { handleAttachClick, sendFile } = rest;
  const webcamRef = useRef(null);

  const [facingMode, setFacingMode] = useState("user");

  const capture = useCallback(() => {
    const imageBase64 = webcamRef.current.getScreenshot();
    if (imageBase64) {
      const repair = imageBase64.replace("data:image/jpeg;base64,", "");

      let arr = `data:image/jpeg;base64,${repair}`.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      const file = new File([u8arr], `${uuidv4()}.jpg`, {
        type: mime,
      });
      sendFile(file, FILETYPE.IMAGE);
      handleAttachClick();
    }
  }, [handleAttachClick, sendFile]);

  const handleFacingMode = () => {
    if (facingMode === "user") {
      setFacingMode("environment");
    } else {
      setFacingMode("user");
    }
  };

  return (
    <div className="camera-screen">
      <Webcam
        className="camera-screen__canvas"
        audio={false}
        height={1080}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={1920}
        screenshotQuality={1}
        videoConstraints={{ facingMode: facingMode }}
      />
      <div className="camera-screen__nav">
        <button onClick={handleAttachClick}>
          <MdKeyboardArrowLeft />
        </button>
        <button onClick={capture}>
          <MdCameraEnhance />
        </button>
        <button onClick={handleFacingMode}>
          <IoIosReverseCamera />
        </button>
      </div>
    </div>
  );
}
